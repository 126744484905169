<template>
  <component
    :is="char.empty ? 'span' : 'div'"
    v-if="char"
    :id="name"
    :class="{ 'relative inline-block w-fit scale-0': !char.empty }"
    :style="{
      marginLeft: char.space + 'em',
      zIndex: char.zIndex ?? 0,
    }"
  >
    {{ char.empty ? " " : char.value }}
  </component>
  <div v-else :id="name" class="relative inline-block w-fit scale-0">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { MotionVariants } from "@vueuse/motion";

const props = defineProps<{
  char?: {
    value?: string;
    empty: boolean;
    space?: number;
    zIndex?: number;
  };
  delay?: number;
  speed?: number;
  name?: string;
  init?: boolean;
  playOnce?: boolean;
}>();

const { char, delay, speed, name, init } = props;

const hasAnimationRun = ref(false);

watch(
  () => props.init,
  (val) => {
    if (val) {
      handleAnimation();
      hasAnimationRun.value = true;
    }
  },
);

onMounted(() => {
  if (init) {
    handleAnimation();
    hasAnimationRun.value = true;
  }
});

const handleAnimation = () => {
  if (hasAnimationRun.value) return;
  const char = document.querySelector(`#${name}`);

  const variants: MotionVariants<string> = {
    initial: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        delay: (speed ?? 0) + (delay ?? 0),
        type: "spring",
        stiffness: 500,
        damping: 30,
        mass: 3,
      },
    },
  };

  useMotion(char, variants);

  if (props.playOnce) variants.initial = { scale: 1 };
};
</script>
